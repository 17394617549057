export enum PreferenceType {
  rendering,
}

export class PreferenceValueType<T> {
  static readonly boolean = new PreferenceValueType<boolean>();
  static readonly string = new PreferenceValueType<String>();

  private get(): T | null {
    return null;
  }
}

export class Preference<T> {
  constructor(
    public readonly id: string,
    public readonly type: PreferenceType,
    public readonly valueType: PreferenceValueType<T>,
    public readonly defaultValue: T
  ) {}
}
