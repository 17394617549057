import { useContext } from 'react';
import { Box, CheckBox } from 'grommet';
import { PreferencesContext, PreferenceValue } from '../preferences_hook';
import {
  Preference,
  PreferenceType,
  PreferenceValueType,
} from '../preferences';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from 'reactfire';
import { Analytics, logEvent } from 'firebase/analytics';

interface PreferencesDialogProps {
  preferenceTypes?: PreferenceType[];
}

function renderControl(
  preferenceValue: PreferenceValue<any>,
  setPreferenceValue: <T>(preference: Preference<T>, value: T) => void,
  analytics: Analytics
) {
  if (preferenceValue.preference.valueType === PreferenceValueType.boolean) {
    return (
      <CheckBox
        checked={preferenceValue.value}
        onChange={(e) => {
          setPreferenceValue(preferenceValue.preference, e.target.checked);
          logEvent(analytics, 'preference_change', {
            preference: preferenceValue.preference.id,
            value: e.target.checked,
          });
        }}
      />
    );
  }
  return <></>;
}

function PreferencesDialog({ preferenceTypes }: PreferencesDialogProps) {
  const preferenceValues = useContext(PreferencesContext);
  const { t } = useTranslation();
  const analytics = useAnalytics();

  return (
    <Box pad="medium" gap="small">
      {preferenceValues.preferenceValues
        .filter(
          (v) => preferenceTypes == null || v.preference.type in preferenceTypes
        )
        .map((v) => (
          <Box key={v.preference.id} direction="row" align="center" gap="small">
            <label>{t(`preferences.${v.preference.id}.label`)}</label>
            {renderControl(v, preferenceValues.setPreferenceValue, analytics)}
          </Box>
        ))}
    </Box>
  );
}

export default PreferencesDialog;
